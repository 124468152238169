var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rego-search-content mx-auto"},[_vm._m(0),_c('div',{staticClass:"position-relative"},[_c('custom-select',{class:{
        error: _vm.$v.regoSearchInfo.state.$error,
        'shadow-none mt-35': true,
      },attrs:{"label":"State","options":_vm.stateOptions,"error":_vm.$v.regoSearchInfo.state.$error},model:{value:(_vm.regoSearchInfo.state),callback:function ($$v) {_vm.$set(_vm.regoSearchInfo, "state", $$v)},expression:"regoSearchInfo.state"}}),(
        _vm.$v.regoSearchInfo.state.$dirty && !_vm.$v.regoSearchInfo.state.required
      )?_c('p',{staticClass:"msg-error mb-0"},[_c('i',[_vm._v("Please select state")])]):_vm._e()],1),_c('div',{staticClass:"position-relative"},[_c('float-label',{attrs:{"label":"Rego"}},[_c('b-input',{class:{
          error: _vm.$v.regoSearchInfo.regoNumber.$error,
          'shadow-none mt-27': true,
        },attrs:{"placeholder":"Enter rego","autocomplete":"off","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}},model:{value:(_vm.regoSearchInfo.regoNumber),callback:function ($$v) {_vm.$set(_vm.regoSearchInfo, "regoNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"regoSearchInfo.regoNumber"}})],1),(
        _vm.$v.regoSearchInfo.regoNumber.$dirty &&
          !_vm.$v.regoSearchInfo.regoNumber.required
      )?_c('p',{staticClass:"msg-error mb-0"},[_c('i',[_vm._v("Please enter rego number")])]):(
        _vm.$v.regoSearchInfo.regoNumber.$dirty &&
          !_vm.$v.regoSearchInfo.regoNumber.alphaNum
      )?_c('p',{staticClass:"msg-error mb-0"},[_c('i',[_vm._v("Please enter only alphabet characters and numerics")])]):(
        _vm.$v.regoSearchInfo.regoNumber.$dirty &&
          !_vm.$v.regoSearchInfo.regoNumber.maxLength
      )?_c('p',{staticClass:"msg-error mb-0"},[_c('i',[_vm._v("Please enter 1 to 9 characters!")])]):_vm._e()],1),_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"item col-5"},[_c('button',{staticClass:"outline-btn-skip btn-skip fs-16 text-success",on:{"click":_vm.goTo}},[_c('span',{staticClass:"font-weight-regular"},[_vm._v("Skip rego")])])]),_c('div',{staticClass:"item col-7"},[_c('button',{ref:"searchButton",class:[
          _vm.activeBtnGo ? 'active-btn-go' : 'default-btn-go',
          'btn-go fs-16 text-white' ],attrs:{"disabled":_vm.isLoadingApi},on:{"click":_vm.regoSearch}},[(!_vm.isLoadingApi)?_c('b',{staticClass:"font-weight-regular"},[_vm._v("Let's go")]):_c('b-spinner',{staticClass:"btn-loading",attrs:{"label":"Loading..."}})],1)])]),_c('div',{staticClass:"position-relative"},[(_vm.isLoadingApi)?_c('p',{staticClass:"mb-0 msg-api api-loading"},[_c('i',[_vm._v("Searching for your details")])]):_vm._e(),(_vm.apiNotFound & !_vm.isLoadingApi)?_c('p',{staticClass:"text-center mb-0 msg-api api-not-found"},[_c('i',[_vm._v("No results")])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"fs-20 mb-2 title-rego-search"},[_c('b',[_vm._v("Get the best price from our large network of dealers across Australia")])])}]

export { render, staticRenderFns }