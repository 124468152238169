<template>
  <div class="reason-info-container">
    <div class="top-content-info">
      <h2 class="fs-36 lh-24 reason-info-title">
        Why Sell Your Car with AutoFlip?
      </h2>
      <div class="reason-info">
        Sell your car for the best trade-in price, without the hassle. Sit back
        while we do the negotiating for you.
      </div>
    </div>
    <div class="bottom-content-info px-2">
      <b-row class="mobile-cards">
        <b-col v-for="item of items" :key="item.id" class="p-3">
          <ReasonInfoCard
            :path="item.path"
            :title="item.title"
            :subtitle="item.subtitle"
          />
        </b-col>
      </b-row>
      <b-row class="desktop-cards">
        <b-col v-for="item of items" :key="item.id" class="p-3">
          <ReasonInfoCardDesktop
            :path="item.path"
            :title="item.title"
            :subtitle="item.subtitle"
          />
        </b-col>
      </b-row>
      <button @click="scrollToTop" class="btn-sell">SELL MY CAR</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "WhyUse",
  components: {
    ReasonInfoCard: () => import("./ReasonInfoCard.vue"),
    ReasonInfoCardDesktop: () => import("./ReasonInfoCardDesktop.vue"),
  },
  data() {
    return {
      items: [
        {
          id: 1,
          path: "fast",
          title: "Sell your car fast",
          subtitle:
            "Genuine offers can be received within hours of listing with quick payment. No roadworthy required.",
        },
        {
          id: 2,
          path: "sell",
          title: "Get the best price",
          subtitle:
            "Access 500+ serious licensed buyers ready to buy now. Stress free, we handle the negotiations.",
        },
        {
          id: 3,
          path: "hassle-free",
          title: "Hassle-free experience",
          subtitle:
            "Simple contactless online process and local customer support. Sell from the comfort of your home.",
        },
        {
          id: 4,
          path: "safe",
          title: "Safe & secure",
          subtitle:
            "Avoid scammers and strangers visiting your home. Trusted licensed buyers only.",
        },
        {
          id: 5,
          path: "free-to-list",
          title: "Free to list",
          subtitle:
            "No deal, no fee, and no hidden advertising costs. And if there's no sale, you won't pay anything.",
        },
      ],
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss">
.reason-info-container {
  background: #f9fafb;
  border-radius: 4px;

  .top-content-info {
    text-align: center;
    padding-top: 75px;
  }

  .bottom-content-info {
    padding-bottom: 50px;
    padding-top: 32px;
    margin: 0 auto;
    .desktop-cards {
      display: none;
    }
    .btn-sell {
      width: 159px;
      height: 51px;
      background: #1f8252;
      border: none;
      outline: none;
      color: white;
      border-radius: 6px !important;
      margin-top: 35px;
      font-size: 15px;
      &:hover {
        background-color: #1c7247;
      }
    }
  }
}

@media screen and(max-width: 900px) {
  .top-content-info {
    .reason-info-title {
      font-size: 30px;
      line-height: 36px;
      color: #414141;
    }

    .reason-info {
      font-family: "Avenir Next LT W05 Regular";
      font-size: 16px;
      line-height: 24px;
      color: #6f6f6f;
      padding: 10px 12px;
    }
  }
  .bottom-content-info {
    max-width: 390px;
    padding-bottom: 20px;
    padding-top: 0 !important;
    .mobile-cards {
      flex-direction: column;
    }
  }
}

@media screen and(min-width: 900px) {
  .top-content-info {
    padding-top: 50px !important;
    .reason-info-title {
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 16px;
      font-weight: 600;
      letter-spacing: -0.02em;
      color: #414141;
    }

    .reason-info {
      font-family: "Avenir Next LT W05 Regular";
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #6f6f6f;
    }
  }

  .bottom-content-info {
    max-width: 1200px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    .mobile-cards {
      display: none !important;
    }
    .desktop-cards {
      display: flex !important;
      margin-bottom: 40px;
    }
    .btn-sell {
      font-size: inherit !important;
    }
  }
}
</style>
