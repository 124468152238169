<template>
  <div>
    <div class="rego-wrapper">
      <div class="rego-content">
        <div class="menu" :class="[toggleMenu ? 'menu-toggle' : 'menu']">
          <div class="d-flex justify-content-between pb-3">
            <img
            src="@/assets/images/logo_header_lp.svg"
            @click="$router.go()"
            class="menu-logo cursor-pointer ml-4"
            />
            <img class="mr-4" src="../../assets/images/icons/ic_close_menu.svg" alt="" @click="handleToggleMenu">
          </div>

          <ul class="menu-list font-weight-regular text-left">
            <li class="menu-item"><a href="https://idomi.com.au/autoflip/" target="_blank">ABOUT US</a></li>
            <li class="menu-item"><a href="http://platform.autoflip.com.au" target="_blank">FOR DEALERS</a></li>
            <li class="menu-item"><a href="https://seller.autoflipenterprise.com.au" target="_blank">FOR ENTERPRISE</a></li>
          </ul>

          <ul class="menu-list-bottom">
            <li class="menu-item-bottom">
              <a href="mailto:info@autoflip.com.au" class="d-flex align-items-center ml-4">
                <img
                  src="@/assets/images/icons/ic_mail.svg"
                  class="cursor-pointer menu-logo-contact"
                />
                <p class="fs-14 mb-0 ml-3 font-weight-thin">info@autoflip.com.au</p>
              </a>
            </li>
            <li class="menu-item-bottom">
              <a href="tel:1300816821" class="d-flex align-items-center ml-4">
                <img
                  src="@/assets/images/icons/ic_call.svg"
                  class="cursor-pointer menu-logo-contact"
                />
                <p class="fs-14 mb-0 ml-3 font-weight-thin">1300 816 821</p>
              </a>
            </li>
          </ul>
        </div>

        <div class="top-content-rego-wrapper" :class="isScroll ? 'top-content-header' : ''">
          <div class="top-content-rego d-flex mx-auto">
            <div class="hamburger">
              <img src="@/assets/images/icons/ic_hamburger.svg" alt="" @click="handleToggleMenu">
            </div>
            <div>
              <img
                src="@/assets/images/logo_header_lp.svg"
                @click="$router.go()"
                class="cursor-pointer"
              />
            </div>
            <div class="d-flex">
              <!-- <div class="top-content-info font-weight-regular">
                <a href="https://idomi.com.au/autoflip/" target="_blank">ABOUT US</a>
                <a href="http://platform.autoflip.com.au" target="_blank">FOR DEALERS</a>
                <a href="https://seller.autoflipenterprise.com.au" target="_blank">FOR ENTERPRISE</a>
              </div> -->
              <div class="d-flex text-white contact">
                <a href="mailto:info@autoflip.com.au" class="d-flex">
                  <img
                  src="@/assets/images/icons/ic_mail.svg"
                  class="cursor-pointer mr-12"
                  />
                  <p class="mb-0 fs-14">info@autoflip.com.au</p>
                </a>
                <a href="tel:1300816821" class="d-flex">
                  <img src="@/assets/images/icons/ic_call.svg" class="contact-phone" />
                  <p class="mb-0 fs-14">1300 816 821</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-form">
          <div class="bottom-content-rego text-white text-left">
            <p class="fs-24 mb-0 lh-29 copy-text bottom-content-title">
              The smart way<br />to sell your car fast<br />for the best price
            </p>
            <div class="reviews-badges">
              <a
                href="https://www.google.com/search?q=autoflip&rlz=1C1GCEA_enAU1009AU1010&oq=auto&aqs=chrome.1.69i60j69i59l2j69i57j69i60l2j69i65j69i60.2288j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6ad643c9cf26ce91:0x52eb8c8ec5ff06e6,1,,,"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://embedsocial.com/api/source_image_badges/svg-badge-1-g/en/google/52337"
                />
              </a>
            </div>
          </div>
          <div class="rego-desktop">
            <RegoSearchForm />
          </div>
        </div>
      </div>
    </div>
    <div class="rego-search text-left">
      <RegoSearchForm />
    </div>
  </div>
</template>

<script>
import RegoSearchForm from "./RegoSearchForm.vue";

export default {
  components: { RegoSearchForm },
  name: "RegoSearch",
  data() {
    return {
      toggleMenu: false,
      isScroll: false,
    };
  },
  methods: {
    handleToggleMenu() {
      this.toggleMenu = !this.toggleMenu;
    },
    handleScroll () {
      this.isScroll = window.scrollY > 50;
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style lang="scss">
.rego-wrapper {
  height: 250px;
  max-height: 250px;
  background-image: url("~@/assets/images/bg_top_page.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .rego-content {
    width: 100%;
    height: 100%;
    background-color: #000000ad;

    .menu-toggle {
      transform: translateY(0) !important;
    }
    .menu {
      position: fixed;
      transform: translateY(-100%);
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
      background-color: #313131;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: white;
      list-style: none;
      z-index: 99;
      font-size: 18px;
      padding-top: 1.4rem;
      margin-bottom: 0;
      line-height: 55px;
      .menu-list {
        padding-left: 0;
        list-style: none;
        .menu-item {
          border-top: solid #C4C4C4 0.3px;
          padding-left: 2rem;
          &:last-child {
            border-bottom: solid #C4C4C4 0.3px;
          }
        }
      }
      .menu-list-bottom {
        width: 100%;
        position: absolute;
        padding-left: 0;
        list-style: none;
        bottom: 0;
        .menu-item-bottom{
          border-top: solid #C4C4C4 0.3px;
        }
        .menu-logo-contact{
          width: 25px;
          height: 25px;
        }
      }
      .menu-logo {
        width: 180px;
        height: 35px;
      }
      li a {
        color: white;
        text-decoration: none;
      }
    }

    .reviews-badges {
      text-align: center;

      img:first-child {
        width: 150px;
        height: 75px;
      }
    }

    .top-content-rego-wrapper {
      .top-content-rego {
        max-width: 1073px;
        padding-top: 29px;
        justify-content: space-around;

        .top-content-info {
          display: none;
        }

        .contact {
          .contact-phone {
            margin-right: 7px;
          }
        }

        img {
          width: 175px;
          height: 30px;
        }

        .hamburger {
          width: 62px;

          img {
            width: 25px;
            height: 25px;
          }
        }

        .contact {
          height: fit-content;

          a {
            color: #fff;
            text-decoration: none;
          }

          p {
            display: none;
            margin-top: auto;
          }

          img {
            width: 25px !important;
            height: 25px !important;
            margin-right: 10px;
          }
        }
      }
    }

    .bottom-content-rego {
      padding-left: 30px;
      padding-right: 33px;
      padding-top: 20px;
      bottom: 37px;

      .bottom-content-title {
        text-align: center;
      }

      .desc {
        color: #efefef;
        font-size: 13px;
        line-height: 15px;
        word-spacing: 0.2px;
        margin-top: 1px;
      }
    }

    .rego-desktop {
      display: none;
    }
  }
}

.rego-search {
  padding: 20px 67px 30px;
  background-color: #ffffff;
}

@media screen and(min-width: 768px) and(min-height: 560px) {
  .rego-wrapper {
    height: calc(100vh - 100px) !important;
    max-height: unset !important;

    .reviews-badges {
      text-align: left !important;

      img:first-child {
        width: 294px !important;
        height: 79px !important;
        padding-right: 20px;
      }
    }

    .top-content-header {
      background-color: #313131;
      -webkit-transition: all ease-out .5s;
      -moz-transition: all ease-out .5s;
      -o-transition: all ease-out .5s;
      transition: all ease-out .5s;
      z-index: 9999;
    }

    .top-content-rego-wrapper {
      width: 100%;
      position: fixed;
      left: 50%;
      transform: translate(-50%);
      .top-content-rego {
        padding: 23px 20px !important;
        // padding: 30px 60px 0;
        justify-content: space-evenly;
        align-items: center;
  
        .top-content-info {
          display: block !important;
          padding-top: 4px !important;
          margin-left: 20px;
          a {
            color: #FFFFFF !important;
            text-decoration: none;
            margin-left: 10px;
            margin-right: 10px;
          }
        }
  
        .contact {
          .contact-phone {
            margin-left: 7px;
          }
          p {
            display: none;
          }
        }
  
        img {
          width: 210px !important;
          height: 40px !important;
          margin-left: 0;
        }
  
        .hamburger {
          display: none;
        }
  
        .contact {
          a:last-child {
            margin-left: 20px;
          }
        }
      }
    }

    .bottom-content-rego {
      padding: 0 !important;
      .bottom-content-title {
        text-align: unset !important;
      }
    }

    .flex-form {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -54%);
      max-width: 1073px;
      width: 100%;
      padding: 0 20px;

      .copy-text {
        font-size: 40px;
        line-height: 50px;
      }

      .rego-desktop {
        display: block !important;
      }
    }
  }

  .rego-search {
    display: none;
  }
}

@media screen and(min-width: 1200px) and(min-height: 560px){
  .top-content-rego-wrapper {
    .top-content-rego {
      padding: 30px 0 0 !important;
      justify-content: space-between !important;
  
      .top-content-info {
        margin-left: 300px !important;
      }
  
      .contact {
        p {
          display: block !important;
        }
      }
    }
  }

  .flex-form {
    width: 1073px !important;
    padding: 0 !important;

    .copy-text {
      font-size: 62px !important;
      line-height: 75px !important;
    }
  }
}
</style>
