<template>
  <div class="info">
    <p class="title-info font-weight-bold">
      <b class="easy-step-txt">Sell your car fast in a few simple steps</b>
      <br>
      <b class="easy-step-txt-grey">Quick. Simple. Easy. There's no obligation to list your vehicle and no fee if there's no sale.</b>
    </p>
    <div class="type-info">
      <div class="item mt-32 d-flex align-items-center">
        <div class="img-section">
          <img src="@/assets/images/easy-steps/enter_your_car_details.png" class="img" />
        </div>
        <div class="step-section">
          <span class="step-item">1</span>
        </div>
        <div class="text-section ml-20">
          <p class="title-item mb-0 font-weight-bold">
            <b>Enter your car details</b>
          </p>
          <p class="desc-item">
            Provide your vehicle information (including photos if available). See <a :href="`${autoflipUrl}/photo-guide`" target="_blank">tips</a> for taking great photos.
          </p>
        </div>
      </div>
      <div class="item mt-32 d-flex align-items-center flex-row-reverse">
        <div class="img-section">
          <img src="@/assets/images/easy-steps/vehicle_listing.png" class="img ml-20" />
        </div>
        <div class="step-section">
          <span class="step-item">2</span>
        </div>
        <div class="text-section ml-0">
          <p class="title-item mb-0 font-weight-bold">
            <b>Vehicle listing</b>
          </p>
          <p class="desc-item">
            A friendly team member is available for support and will contact you before listing.
          </p>
        </div>
      </div>
      <div class="item mt-32 d-flex align-items-center">
        <div class="img-section">
          <img src="@/assets/images/easy-steps/receive_best-price_offer.png" class="img" />
        </div>
        <div class="step-section">
          <span class="step-item">3</span>
        </div>
        <div class="text-section ml-20">
          <p class="title-item mb-0 font-weight-bold">
            <b>Receive best price offer</b>
          </p>
          <p class="desc-item">
            500+ trusted dealers compete to buy your car. We then respond with the best price offer.
          </p>
        </div>
      </div>
      <div class="item mt-32 d-flex align-items-center">
        <div class="img-section">
          <img src="@/assets/images/easy-steps/get_cash_quickly.png" class="img" />
        </div>
        <div class="step-section">
          <span class="step-item">4</span>
        </div>
        <div class="text-section ml-20">
          <p class="title-item mb-0 font-weight-bold">
            <b>Get cash quickly</b>
          </p>
          <p class="desc-item">
            Accept the offer and pay the <a :href="`${autoflipUrl}/seller-fees`" target="_blank">success fee</a>. Arrange free pick-up and get paid fast.
          </p>
        </div>
      </div>
    </div>
    <button @click= "scrollToTop" class="btn-sell">Sell my car</button>
  </div>
</template>

<script>
import { AUTOFLIP_URL } from "@/helper/constant";
export default {
  name: "Info",
  data() {
    return {
      autoflipUrl: AUTOFLIP_URL,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
};
</script>

<style lang="scss">
.info {
  background-color: #FFFFFF;
  padding: 61px 0 69px;
  max-width: 389px;
  margin: 0 auto;

  .title-info {
    line-height: 28px;
    font-size: 26px;
    .easy-step-txt {
      font-family: 'Avenir Next LT W05 Regular';
      color: #414141;
      font-style: normal;
      font-size: 36px;
      line-height: 40px;
    }
    .easy-step-txt-grey {
      font-family: 'Avenir Next LT W05 Regular';
      color: #6F6F6F;
      font-style: normal;
      font-size: 16px;
      line-height: 28px;
    }
  }

  .ml-20 {
    margin-left: 20px;
  }

  .ml-32 {
    margin-top: 32px;
  }

  .type-info {
    .item {
      padding: 0 27px 0 35px;
    }

    .text-section {
      text-align: left;
      .title-item {
        line-height: 28px;
        margin-top: 10px;
        font-size: 20px;
        font-family: 'Avenir Next LT W05 Regular' !important;
        @media screen and (max-width: 320px) {
          font-size: 14px;
        }
      }
      
      .desc-item {
        line-height: 24px;
        margin: 3px 0 0 0;
        font-size: 16px;
        font-family: 'Avenir Next LT W05 Regular'!important;
        color: #6F6F6F;
        @media screen and (max-width: 320px) {
          font-size: 12px;
        }
        a {
          text-decoration: underline;
          color: #1F8252;
        }

      }
    }

    .img-section {
      .img {
        width: 280px;
        height: 157.42px;
        margin-bottom: 50px;

        @media screen and (max-width: 375px) {
          width: 260px;
          height: 137.42px;
        }

        @media screen and (max-width: 320px) {
          width: 240px;
          height: 117.42px;
        }
      }
    }

    .step-section {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #1F8252;
      border-radius: 9999px;
      .step-item {
        font-family: 'Avenir Next LT W05 Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
      }
    }
  }

  .btn-sell {
    width: 145.48px;
    height: 50px;
    background: #1F8252;
    border-radius: 6px !important;
    border: 1px solid #1F8252;
    color: white;
    font-family: 'Avenir Next LT W05 Regular' !important;
    margin-top: 35px;
    font-size: 15px;
  }

}

@media screen and(min-width: 900px) {
  .info {
    width: 90%;
    max-width: 1200px;
    padding: 30px 0;
    margin-bottom: 48px;

    .title-info {
      font-size: 36px !important;
      line-height: 36px !important;
      margin: 40px 0;
      .easy-step-txt {
        font-size: 36px;
        line-height: 40px;
      }
      .easy-step-txt-grey {
        font-size: 16px;
      }
    }

    .type-info {
      display: flex;
      justify-content: space-between;

      .item {
        width: calc(25% - 15px);
        position: relative;
        display: flex;
        flex-direction: column !important;
        align-items: center;
        padding: 0;

        .desc-item {
          max-width: 260px;
          margin-top: 10px;
        }

        &:nth-child(2) {
          .desc-item {
            max-width: 250px;
          }
        }
      }

      .text-section {
        text-align: center;
        margin-left: 0 !important;

        .title-item {
          font-size: 20px !important;
        }
      }

      .img-section {
        .img {
          width: 280px;
          height: 157.42px;
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media screen and(max-width: 900px) {
  .info {
    .title-info {
      .easy-step-txt {
        font-size: 30px;
        line-height: 36px;
      }
      .easy-step-txt-grey {
        font-size: 16px;
        line-height: 28px;
      }
    }

    .type-info {
      .item {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        margin-bottom: 60px;
      }
      .text-section {
        text-align: center;
        margin-left: 0 !important;

        .title-item {
          text-align: center;
        }

        .desc-item {
          text-align: center;
        }
      }
      .img-section {
        .img {
          width: 358px;
          height: 201.28px;
          margin-left: 0 !important;
        }
      }
    }
  }
}

</style>