<template>
  <label class="form-group border-label-flt mb-0">
    <slot></slot>
    <span> {{ label }} </span>
  </label>
</template>

<script>
export default {
  name: 'FloatLabel',
  props: ['label']
}
</script>

<style lang="scss">
.border-label-flt {
  display: block;
  position: relative;
}

.border-label-flt label, .border-label-flt > span {
  position: absolute;
  cursor: text;
  font-size: 12px;
  opacity: 1;
  -webkit-transition: all .2s;
  transition: all .2s;
  top: -.5em;
  left: 20px;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  font-weight: bold;
  color: #000000;
}

.border-label-flt label::after, .border-label-flt > span::after {
  content: " ";
  display: block;
  position: absolute;
  background: white;
  height: 2px;
  top: 50%;
  left: -.2em;
  right: -.2em;
  z-index: -1;
}

.border-label-flt .form-control::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.border-label-flt .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

//.border-label-flt .form-control:placeholder-shown:not(:focus) + * {
//  color: #B3B3B3;
//  font-size: 16px;
//  top: 50%;
//  transform: translate(0, -50%);
//
//  &::after {
//    display: none;
//  }
//}
//
//.border-label-flt .unselect + * {
//  color: #B3B3B3;
//  font-size: 16px;
//  top: 50%;
//  transform: translate(0, -50%);
//
//  &::after {
//    display: none;
//  }
//}

::placeholder, ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder {
  opacity: 1;
  color: #989898 !important;
}

.border-label-flt .form-control::placeholder {
  opacity: .5;
  font-size: 1rem;
  font-weight: 500;
  opacity: 1;
  color: #989898 !important;
}

.input-group .border-label-flt {
  display: table-cell;
}

.input-group .border-label-flt .form-control {
  border-radius: 0.25rem;
}

.input-group .border-label-flt:not(:last-child), .input-group .border-label-flt:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .border-label-flt:not(:first-child), .input-group .border-label-flt:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
</style>
