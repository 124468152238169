<template>
  <div>
    <FloatLabel :label="label">
      <select
          class="shadow-none form-control select-custom"
          v-model="val"
          v-on:change="$emit('change', $event.target.value)"
          :id="error ? 'invalid' : ''"
          :class="[`${this.selectValue !== null ? '' : 'gray'}`]"
      >
        <option selected value="nonData" disabled v-if="options.length === 0">No data</option>
        <option v-else v-for="(e, idx) in options" :key="idx" :value="e" :disabled="e === null">
          {{ e ? e : 'Select one' }}
        </option>
      </select>
    </FloatLabel>
  </div>
</template>

<script>
export default {
  components: {
    FloatLabel: () => import('./FloatLabel.vue')
  },
  name: 'CustomSelect',
  inheritAttrs: false,
  model: {
    prop: 'selectValue',
    event: 'change'
  },
  props: {
    selectValue: {
      type: [Number, String],
      default: () => ""
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: String,
    error: Boolean
  },
  computed: {
    val: {
      get: function () {
        return this.selectValue;
      },
      set: function () {
      },
    }
  }
}
</script>

<style lang="scss">
select.form-control#invalid {
  border-color: #C1272D !important;
}

.select-custom {
  background-size: 16px;
  background-position: calc(100% - 10px) center;

  &:focus {
    color: #383c3f !important;
  }

  &.gray {
    color: #989898 !important;
  }
  
  option {
    color: #383c3f !important;

    &:first-child {
      color: #989898 !important;
    }
  }
}
</style>
