<template>
  <div class="car-sale">
    <h2 class="fs-24 car-sale-title font-weight-bold">
      Successful sales
    </h2>
    <p class="fs-14 font-weight-thin mt-0 mb-5 car-sale-subtitle">
      Here's a snapshot of some of the cars we helped sell!
    </p>
    <div class="position-relative car-sale-content mx-auto">
      <img class='icon-prev slick-prev' src='@/assets/images/icons/ic_prev.svg' @click="showPrev">
      <img class='icon-next slick-next' src='@/assets/images/icons/ic_next_single.svg' @click="showNext">
      <vue-slick-carousel v-bind="settings" class="car-sale-slide mx-auto" ref="slide">
        <div v-for="(car,index) in carSale" :key="index" class="item text-left">
          <div class="img" :style="{ backgroundImage: `url(${require(`@/assets/images/successful_sales/${car.url}`)})` }"></div>
          <div class="car-sale-info">
            <p class="fs-20 mb-0 lh-24"><b>{{ car.name }}</b></p>
            <p class="fs-12 mb-0 lh-24">{{ car.desc }}</p>
            <div class="d-flex offer-deal">
              <div class="d-flex flex-column align-items-center">
                <p class="mb-0 fs-8 label"><b>Seller expectation</b></p>
                <p class="value-offer"><b>{{ car.offer }}</b></p>
              </div>
              <div class="d-flex flex-column align-items-center">
                <p class="mb-0 fs-10 label invisible"><b>Arrow icon</b></p>
                <img src="@/assets/images/icons/ic_arrow.svg" class="ic-arrow">
              </div>
              <div class="d-flex flex-column align-items-center">
                <p class="mb-0 fs-8 label"><b>AF deal</b></p>
                <p class="value-deal"><b>{{ car.deal }}</b></p>
              </div>
            </div>
            <div class="d-flex align-items-center flex-wrap km-area">
              <div class="fs-12 lh-24">
                <b>{{ car.km }}</b>
              </div>
              <div class="fs-12 lh-24">
                <b>{{ car.area }}</b>
              </div>
            </div>
          </div>
        </div>
      </vue-slick-carousel>
      <button @click="scrollToTop" class="btn-sell">SELL MY CAR</button>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import constants from '@/config/constants'

export default {
  name: "CarSale",
  data() {
    return {
      carSale: constants.CARSALE,
      isMobile: true,
    }
  },
  methods: {
    showPrev() {
      this.$refs.slide.prev()
    },
    showNext() {
      this.$refs.slide.next()
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
  computed: {
    mobileMediaQuery: function() { 
      return window.matchMedia('(min-width: 1200px)');
    },
    settings: function() {
      return {
        "dots": true,
        "dotsClass": "slick-dots custom-dots",
        "arrows": false,
        "focusOnSelect": true,
        "swipeToSlide": true,
        "infinite": true,
        "speed": this.isMobile ? 500 : 800,
        "slidesToShow": this.isMobile ? 1 : 4,
        "slidesToScroll": 1
      }
    },
  },
  components: {
    VueSlickCarousel
  },
  mounted() {
    this.mobileMediaQuery.addListener(e => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
  },
}
</script>

<style lang="scss">
.car-sale {
  background-color: #e0e2de;
  padding-bottom: 55px;

  .car-sale-title {
    line-height: 28px;
    padding-top: 74px;
    margin-bottom: 16px;
  }

  .car-sale-content {
    max-width: 389px;

    .btn-sell{
      width: 159px;
      height: 51px;
      background-color: #2bb673;
      border: none;
      outline: none;
      color: white;
      border-radius: 8px !important;
      margin-top: 95px;
      font-size: 15px;
      &:hover {
        background-color: #20a766;
      }
    }

    .car-sale-slide {
      max-width: 307px;

      .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          max-width: 287px;
        }

        .item {
          width: fit-content;
          height: 330px;
          background-color: #FFFFFF;
          border-radius: 10px;

          .img {
            width: 287px;
            height: 160px;
            background-color: #DADADA;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }

          .car-sale-info {
            padding: 12px 15px 0;

            .price {
              color: #00B86D;
              margin-top: 9px;
              margin-bottom: 18px;
            }

            .offer-deal {
              margin-top: 12px;

              p {
                margin-right: auto;
              }

              .label {
                color: #989898;
              }

            }

            .value-offer {
              color: #8CC889;
              font-size: 18px;
              line-height: 24px;
              margin-bottom: 0;
            }

            .ic-arrow {
              width: 20px;
              height: 10px;
              margin: auto 15px auto 0px;
            }

            .value-deal {
              color: #00BB65;
              font-size: 22px;
              line-height: 24px;
              margin-bottom: 0;
            }

            .km-area {
              color: #989898;
              margin-top: 16px;
              div {
                width: 90px;
                height: 25px;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #EFEFEF;
                border-radius: 5px;
              }
              div:last-child {
                width: 120px;
              } 
            }
          }
        }
      }

      .custom-dots {
        bottom: -56px;
        line-height: 0;
        width: 330px;
        margin-left: -12px;

        li {
          width: 10px;
          margin: 0 10px;

          button {
            width: 10px;

            &:before {
              font-size: 10px;
              width: 10px;
              color: #FFFFFF;
              opacity: 1;
            }
          }
        }

        li.slick-active {
          button {
            &:before {
              color: #00BB65;
            }
          }
        }
      }
    }
  }

  .icon-prev, .icon-next {
    width: 20px;
    height: 38px;
  }

  .icon-prev {
    left: 0;
  }

  .icon-next {
    right: 0;
  }
}

@media screen and(min-width: 768px) {
  .car-sale-title {
    font-size: 36px !important;
    line-height: 34px !important;
    padding-top: 66px !important;
  }

  .car-sale-subtitle {
    font-size: 16px !important;
    margin: 0 !important;
  }

  .car-sale-content {
    max-width: 800px !important;
    margin-top: 48px;

    .car-sale-slide {
      max-width: 780px !important;

      .offer-deal p:first-child {
        font-size: 10px !important;
      }

      .custom-dots {
        width: 100% !important;
      }
    }
  }
}

@media screen and(min-width: 1200px) {
  .car-sale-content {
    max-width: 1308px !important;

    .car-sale-slide {
      max-width: 1228px !important;
    }
  }
}
</style>
